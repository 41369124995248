import { addWithRefCount, removeWithRefCount } from 'CasinoGlobal/helpers/with_ref_count';
import { ADD_GAMES, REMOVE_GAMES } from './action_types';

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_GAMES:
      return addWithRefCount(state, action.games);
    case REMOVE_GAMES:
      return removeWithRefCount(state, action.gamesIds);
    default:
      return state;
  }
};
